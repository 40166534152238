<template>
 
  <ion-content >
  <div id="container"  style="margin-left: 24px;margin-right: 24px;">
    <ion-row>
    <ion-col>
    </ion-col>
    <ion-col>
    </ion-col>
    <ion-col>
       <button type="button" @click="closeModal()" style="margin-left: 67%;margin-top: 6px;background-color: #fff;">
        <ion-icon :icon="close"></ion-icon> 
      </button>
    </ion-col>
  </ion-row>
    <ion-title> Editar Siniestro </ion-title>
    <ion-form>
      
      <ion-label style="float: left; ">Ingrese el número de afectado</ion-label><br>
      <ion-input type="number" min="1" v-model="num_clientes" @keyup="generarAfectados" @change="generarAfectados"  placeholder="Ingrese el número de afectado" />

      <div v-for="(cliente,key) in clientes" :key="key">
       <ion-label style="float: left; ">Datos del afectado {{key+1}} </ion-label><br>
        
        <ion-input type="text" v-model="cliente.name"  :placeholder="'Ingrese el nombre del afectado '+(key+1)" />
        <ion-input type="text" v-model="cliente.telefono"  :placeholder="'Ingrese el telefono del afectado '+(key+1)" />
        <ion-input type="text" v-model="cliente.movil"  :placeholder="'Ingrese el movil del afectado '+(key+1)" />
      
        <ion-button color="danger" @click="deleteClient(cliente.id)">Eliminar Implicado</ion-button>
      </div>

      <ion-label style="float: left; ">Número de expediente</ion-label><br>
      <ion-input type="text"  v-model="expediente" placeholder="Ingrese número de expediente (opcional)" />

      <ion-label style="float: left; ">ID Exp</ion-label><br>
      <ion-input type="text"  v-model="expediente_id" placeholder="Ingrese id de expediente (opcional)" />

      <ion-label style="float: left; ">Version del sucedido</ion-label><br>
      <ion-textarea type="text"  v-model="version_suceso" placeholder="Ingrese version del sucedido" />
      
      <ion-label style="float: left; ">Observaciones</ion-label><br>
      <ion-textarea type="text"  v-model="observacion" placeholder="Ingrese la observación" />

      <ion-button color="dark" @click="editSiniesto">Enviar</ion-button>
    </ion-form>
  </div>
</ion-content>

</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { modalController,IonInput,IonTextarea } from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'

export default defineComponent({
  name: 'AgregarColaborador',
  props: ['data_siniestro'],
  components :{IonInput,IonTextarea},
  setup() {
    return { close }
  },
  data(){
    return{ 
        num_clientes : 1,
        clientes : [],
        version_suceso: null,
        observacion: null,
        expediente_id: null,
    }
  },
  mounted(){
    this.version_suceso = this.data_siniestro.version_suceso
    this.expediente = this.data_siniestro.expediente
    this.expediente_id = this.data_siniestro.expediente_id
    this.observacion = this.data_siniestro.observacion
    this.clientes = this.data_siniestro.clientes
    this.num_clientes = this.data_siniestro.clientes.length
  },
  methods:{
    async closeModal() {
      const modal = await modalController
      return modal.dismiss();
    },
    event($event){
      this[$event.target.id] = $event.target.value
    },
    generarAfectados(){
      this.clientes = [];

      for (var i = 0; i < parseInt(this.num_clientes); i++) {
        
        this.clientes[i]= {
          id : this.data_siniestro.clientes[i] !== undefined ? this.data_siniestro.clientes[i].id : null,
          name : this.data_siniestro.clientes[i] !== undefined ? this.data_siniestro.clientes[i].name : null ,
          telefono :  this.data_siniestro.clientes[i] !== undefined ? this.data_siniestro.clientes[i].telefono :null,
          movil :  this.data_siniestro.clientes[i] !== undefined ? this.data_siniestro.clientes[i].movil : null,
        }
      }

    },
    /*getColaboradores(){
      axios.get('/api/users?all=true&role=Colaborador')
      .then(res => {
        console.log(res.data.data)
        this.colaboradores = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },*/
    async editSiniesto(){

      if (this.data_siniestro.clientes.length > this.clientes.length) {
        this.$toast.openToast("No puedes disminuir el número de afectados","error")
        return
      }
      
      var loading = await toast.showLoading()

      await loading.present();

      axios
      .post('/api/siniestros/'+this.data_siniestro.id,
      {
        clientes : this.clientes,
        version_suceso: this.version_suceso,
        expediente: this.expediente,
        expediente_id: this.expediente_id,
        observacion: this.observacion,
      })
      .then(data => {
        loading.dismiss()
        this.closeModal()
        this.$toast.openToast("Edición de siniestro exitoso","success")
        console.log(data)
      }).catch(err => {
        loading.dismiss()
        console.log(err)
      })
    },
    async deleteClient(id){
        if (confirm('¿Estás seguro de que quieres eliminar este elemento?')) {
        // Realizar la petición POST aquí
            axios.post('/api/siniestros/remove/user', { 'user_id': id, 'siniestro_id': this.data_siniestro.id })
            .then(response => {
            // Manejar la respuesta si es necesario
            console.log(response.data);
          })
          .catch(error => {
            // Manejar el error si ocurre
            console.error(error);
          });
        }
    }
  }
});
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-select {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
  height: 50px;
}

ion-textarea {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
</style>
