<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $route.meta.title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.meta.title }} </ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <ion-row>
          <ion-col sizeLg="4" sizeMd="4" sizeXs="12" >
            <ion-searchbar  
              @ionChange="getSearch" 
              v-model="search" 
              show-cancel-button="focus" 
              debounce="1000" 
              placeholder="Buscar Oficina" 
              cancel-button-text="Custom Cancel"
            >
            </ion-searchbar>
          </ion-col>
          <ion-col></ion-col>
          <ion-col>  
            <ion-button color="dark" style="width: 45px;" @click="openModalAdd">
              +
            </ion-button>
          </ion-col>
        </ion-row>
        <div style="margin-left: 24px;margin-right: 24px;">
          <div class="card">
            
              <table class="table table-responsive-sm table-striped table-align-middle">
                <thead>
                  <tr>
                    <th>Nombre del Usuario</th>
                    <th>Correo Electrónico</th>
                    <th>Siniestros Abiertos en el Año</th>
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    v-for="colaborador in colaboradores" 
                    :key="colaborador" 
                    :class="{'selected' : colaborador.id == colaborador_selected}" 
                  >
                    <td>{{colaborador?.user_name}}</td>
                    <td>{{colaborador?.email}}</td>
                    <td> 
                      <ion-badge 
                        color="danger" 
                        style="width: auto;padding-top: 4px; height: 21px;margin-top: 13px;" 
                        title="Nuevas notas no leidos"
                      >
                      {{colaborador?.siniestros_abierto_anual}}
                      </ion-badge>
                    </td>
                    <!--<td>
                      <ion-button color="primary"  @click="showClienteByColaborador(colaborador)">
                        Ver Clientes
                      </ion-button>
                    </td>
                    <td>
                      <ion-button 
                        color="primary" 
                        title="Ver siniestros"  
                        @click="getSiniestrosByUser(colaborador)">
                        <ion-icon  
                          :icon="clipboard" 
                          :ios="clipboardOutline" 
                          :md="clipboard">
                        </ion-icon>
                      </ion-button>
                    </td>-->
                    <td>
                      <ion-button 
                        color="primary" 
                        title="Editar" 
                        @click="openModalEdit(colaborador)"
                      >
                        <ion-icon  
                          :icon="create" 
                          :ios="createOutline" 
                          :md="create">
                            
                        </ion-icon>
                      </ion-button>
                    </td>
                    <td>
                      <ion-button 
                        color="primary" 
                        title="Eliminar" 
                        @click="eliminarUserConfirmacion(colaborador)"
                      >
                        <ion-icon  
                          :icon="trash" 
                          :ios="trashOutline" 
                          :md="trash"
                        ></ion-icon>
                      </ion-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!--<nav aria-label="page navigation example">
                <ul class="pagination justify-content-center">
                  <li  :class="{'page-item' : true , 'disabled' : !colaboradores.prev_page_url }">
                    <a class="page-link"   @click="page(colaboradores.prev_page_url)" tabindex="-1">Anterior</a>
                  </li>
                  <li class="page-item"><a class="page-link">{{colaboradores.current_page}}</a></li>
                  <li :class="{'page-item' : true , 'disabled' : !colaboradores.next_page_url }">
                    <a class="page-link"  @click="page(colaboradores.next_page_url)">Siguiente</a>
                  </li>
                </ul>
              </nav>-->
            </div>
        </div>

        <div id="container">

        <!--<div v-if="has_siniestro">
           
            <br>
            <ion-row>
              <ion-col>
                <div style="margin-left: 24px;margin-right: 24px;">
                  <div class="card">
                      <ion-title size="large">
                        Siniestros
                      </ion-title>
                      
                      <div v-show="loading">
                        <br>
                          <ion-title size="small">
                            Cargando
                          </ion-title>
                        <br>
                      </div>
                      
                      <table  
                        v-show="!loading"  
                        class="table table-responsive-sm table-striped table-align-middle"
                      >
                        <thead>
                          <tr>
                            <th>Clientes</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr 
                            v-for="siniestro in siniestros.data" 
                            :key="siniestro"  
                            :class="{'selected' : siniestro.id == siniesto_selected}"
                          >
                            <td style="width: 135px;">
                              <template 
                                v-for= "(cliente,key) in siniestro.clientes" 
                                :key="cliente">
                                  {{cliente.name}}
                                  <template 
                                    v-if="siniestro.clientes.length != (key+1)"
                                  >
                                  <br>
                                </template>
                              </template>
                            </td>
                            <td>
                              <ion-row>
                                <ion-col>
                                  <ion-badge 
                                    v-if="siniestro.notification.find(not => not.user_id == getUser.id && not.new_notification) " 
                                    color="danger" 
                                    style="width: 18px;padding-top: 7px; height: 21px;margin-top: 13px;" 
                                    title="Nuevas notas no leidos"
                                  >
                                  *
                                  </ion-badge>
                                  <template v-else>
                                    <ion-badge 
                                      v-if="siniestro.chat.messages[siniestro.chat.messages.length-1].user_id != getUser.id || siniestro.chat.messages[siniestro.chat.messages.length-1].read_at == null" 
                                      color="danger" 
                                      style="width: 18px;padding-top: 7px; height: 21px;margin-top: 13px;" 
                                      title="Nuevas notas no leidos">
                                      *
                                    </ion-badge>
                                  </template>
                                </ion-col>
                                <ion-col>
                                  <ion-button 
                                    title="Ver documentos" 
                                    style="font-size: 11px" 
                                    color="primary"  
                                    @click="getDocumentos(siniestro)">
                                    <ion-icon  
                                      :icon="folder" 
                                      :ios="folderOutline" 
                                      :md="folder">
                                    </ion-icon>
                                  </ion-button>
                                </ion-col>
                                
                                <ion-col>
                                  <ion-button 
                                    title="Ver notas" 
                                    style="font-size: 11px" 
                                    color="primary"  
                                    @click="getMessages(siniestro)"
                                  >
                                    <ion-icon  
                                      :icon="chatbubbles" 
                                      :ios="chatbubblesOutline" 
                                      :md="chatbubbles">
                                    </ion-icon>
                                  </ion-button>
                                </ion-col>
                                <ion-col 
                                  v-if="getUser?.role?.name == 'Despacho'"
                                >
                                  <ion-button 
                                    title="Editar siniestro" 
                                    style="font-size: 11px" 
                                    color="primary"  
                                    @click="openModalSiniestroEdit(siniestro)"
                                  >
                                    <ion-icon  
                                      :icon="create" 
                                      :ios="createOutline" 
                                      :md="create">
                                    </ion-icon>
                                  </ion-button>
                                </ion-col>
                                <ion-col 
                                  v-if="getUser?.role?.name == 'Despacho'"
                                >
                                  <ion-button 
                                    title="Archivar/Finalizar Siniestro" 
                                    style="font-size: 11px" 
                                    color="primary"  
                                    @click="archivar(siniestro)" 
                                  >
                                    <ion-icon 
                                      :icon="fileTrayFull" 
                                      :ios="fileTrayFullOutline" 
                                      :md="fileTrayFull">
                                    </ion-icon>
                                    
                                  </ion-button>
                                </ion-col>
                                <ion-col 
                                  v-if="getUser?.role?.name == 'Despacho'"
                                >
                                  <ion-button 
                                    title="Eliminar siniestro" 
                                    style="font-size: 11px" color="primary"  
                                    @click="eliminarSiniestroConfirmacion(siniestro)" 
                                  >
                                    <ion-icon  
                                      :icon="trash" 
                                      :ios="trashOutline" 
                                      :md="trash">
                                    </ion-icon>
                                    
                                  </ion-button>
                                </ion-col>
                              </ion-row>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <nav aria-label="page navigation example">
                        <ul class="pagination justify-content-center">
                          <li  
                            :class="{'page-item' : true , 'disabled' : !siniestros.prev_page_url }"
                          >
                            <a 
                              class="page-link"   
                              @click="pageSiniestro(siniestros.prev_page_url)" 
                              tabindex="-1"
                            >
                              Anterior
                            </a>
                          </li>
                          <li 
                            class="page-item"
                          >
                            <a 
                              class="page-link"
                            >
                              {{siniestros.current_page}}
                            </a>
                          </li>
                          <li 
                            :class="{'page-item' : true , 'disabled' : !siniestros.next_page_url }"
                          >
                            <a 
                              class="page-link"  
                              @click="pageSiniestro(siniestros.next_page_url)"
                            >
                              Siguiente
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                </div>
              </ion-col>  
              <ion-col>
                <div 
                  v-if="show_table == 'notas'" 
                  style="margin-left: 24px;margin-right: 24px;"
                >
                  <div class="card">
                    <ion-title 
                      size="large"
                    >
                      Notas
                      <ion-icon 
                        @click="openPopover" 
                        style="float: right;margin-top: 5px;" 
                        :icon="bookmarks" 
                        :ios="bookmarksOutline" 
                        :md="bookmarks">
                      </ion-icon>
                    </ion-title>
                    <table 
                      class="table table-responsive-sm table-striped table-align-middle"
                    >
                        <thead>
                          <tr>
                            <th>Fecha de registro</th>
                            <th>Nota</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr 
                            v-for="(message,key) in messages.data" 
                            @mouseover="showRow(key)" 
                            @mouseleave="leaveHover(key)" 
                            :key="message"
                          >
                            
                            <td 
                              style="font-size: 12px"
                            >
                              {{new Date(message?.created_at).toLocaleDateString()}} 
                              <br> 
                              {{new Date(message?.created_at).toLocaleTimeString() }}
                            </td>

                            <td v-if="!message.is_file">
                              <ion-label 
                                v-if="message_selected != message"
                                v-html="message.message.replace(/(?:\r\n|\r|\n)/g,'<br>','i')"
                              >
                              </ion-label>
                              
                              <template v-else>
                                <textarea 
                                  v-model="message_edit" 
                                  style="padding-top: 13px" 
                                  placeholder="Escribir un mensaje" 
                                  class="input-text-edit"
                                >
                                </textarea>
                              </template>
                              
                              <template  
                                v-if="message.level == 0 && message_selected != null"
                              > 
                                - <b>(Nota Privada)</b> 
                              </template>
                            </td>
                            
                           
                            
                            <td v-else>
                              <a 
                                :href="BasePublic+'/'+message.message"  
                                target="_blank" 
                                style="color: #777777" 
                                :download="message.message.split('/')[1]"
                              >
                                Descargar archivo adjunto
                                <ion-icon  
                                  :icon="download" 
                                  :ios="downloadOutline" 
                                  :md="download">
                                </ion-icon>
                              </a>
                           
                              <template 
                                v-if="message.level == 0"
                              > 
                                - 
                                <b>
                                  (Nota Privada)
                                </b> 
                              </template>
                            </td>
                            
                            <td>
                              <img 
                                v-if="message_selected != null && message_selected == message"
                                src="assets/img/save.svg" 
                                @click="editMessage()" 
                                style="width: 100px;"
                              >
                            </td>
                            
                            <td>
                              <div 
                                v-if="getUser.role.id == 1" 
                                :id="'edit-'+key" 
                                @click="setMessageEdit(message)" 
                                class="delete-note-not-hover">
                                <ion-icon 
                                  :icon="create" 
                                  :ios="createOutline" 
                                  :md="create"
                                ></ion-icon>
                              </div>
                            </td>
                            
                            <td>
                              <div 
                                :id="'trash-'+key" 
                                @click="eliminarNotaConfirmacion(message)" 
                                class="delete-note-not-hover">
                                <ion-icon 
                                  :icon="trash" 
                                  :ios="trashOutline" 
                                  :md="trash">
                                </ion-icon>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div :style="input_styles" class="message-input">

                        <textarea 
                          v-model="message" 
                          style="padding-top: 13px" 
                          placeholder="Escribir un mensaje" 
                          class="input-text"
                        >
                        </textarea>
                        
                        

                        <i 
                          class="icon" 
                          style="margin-left: -45px;margin-top: -5px;"
                        >
                          <img  
                            style="margin-top: -68px" 
                            @click="setOpen(true)" 
                            src="assets/img/upload_image.svg"
                          >
                        </i> 
                        
                        <img 
                          src="assets/img/send.png" 
                          @click="send()" 
                          style="margin-left: 32px;margin-top: -65px"
                        >
                        
                       
                        <ion-select 
                          v-if="this.getUser.role.name != 'Cliente'"  
                          class="input-text"  
                          interface="action-sheet" 
                          v-model="level" 
                          style="color: #000;margin-left: 10px;"
                        > 
                          <ion-select-option 
                            v-for="option in options" 
                            :value="option.type" 
                            :key="option" 
                          >
                            {{option.label}}
                          </ion-select-option>
                        </ion-select>
                      </div>
                    </div>
                </div>
                <div 
                  v-else 
                  style="margin-left: 24px;margin-right: 24px;"
                >
                  <div 
                    class="card"
                  >
                    <ion-title 
                      size="large"
                    >
                      Documentos
                      <ion-icon 
                      @click="openPopover" 
                      style="float: right;margin-top: 5px;" 
                      :icon="bookmarks" 
                      :ios="bookmarksOutline" 
                      :md="bookmarks">
                      </ion-icon>
                    </ion-title>
                    <table
                      class="table table-responsive-sm table-striped table-align-middle"
                    >
                        <thead>
                          <tr>
                            <th>Nombre del Documento</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr 
                            v-for="documento in documentos.data" 
                            :key="documento"
                          >
                            <td>
                              {{documento?.nombre_documento}}
                              <br>
                              <small>
                                {{documento.documento_firmados.length }} / 
                                {{documento.siniestro.clientes.length}} Firmados
                              </small>
                           
                            </td>
                            <td>
                              <ion-button 
                                v-if="documento.documento_firmados.length != 0" 
                                color="primary"
                                @click="download_documento(documento)"
                              >
                                Descargar documentos firmados
                              </ion-button>
                            </td>
                          </tr>
                          <tr 
                            v-if="documentos?.data?.length == 0"
                          > 
                            <td colspan="2">
                              Actualmente no hay documentos 
                              registrado en ese siniestro
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        v-if="getUser.role.name == 'Despacho'" 
                        class="message-input" 
                      >
                        <ion-input 
                          type="text" 
                          id="user" 
                          class="input-text"  
                          v-model="nombre_documento" 
                          placeholder="Ingrese el nombre del documento" 
                          style="margin-left: 14px;" 
                        />
                        
                        <input 
                          type="file" 
                          ref="documentos" 
                          @change="getFile()" 
                          id="documento" 
                          style="display: none;"
                        >

                        <div 
                          v-if="nombre_file != null"
                        >
                          <br>
                          <ion-label 
                            style="float: left;margin-left: 14px;"
                          >
                            {{nombre_file}}
                          </ion-label>
                          <br>
                          <br>
                        </div>
                        <ion-button 
                          color="dark" 
                          @click="file"
                        >
                          Subir Documento
                        </ion-button>

                        <ion-button 
                          color="dark" 
                          @click="postDocumento"
                        >
                          Enviar
                        </ion-button>
                    </div>
                  </div>
                </div>
              </ion-col>
            </ion-row>
            
          </div>
          <div 
            v-else
          >
            <div 
              v-if="colaborador != null"
            >
              <ion-label>
                No hay registros de siniestros actualmente
              </ion-label>
            </div>
          </div>-->
        </div>
      </div>
    </ion-content>
    <ion-modal
      :is-open="isOpenRef"
      :enterAnimation="enterAnimation"
      :leaveAnimation="leaveAnimation"  
      css-class="my-custom-class"
      @didDismiss="setOpen(false)"
      @ionModalWillDismiss="setOpen(false)"
    >
      <ModalUpload 
        @get="getPhoto($event)" 
        @close="setOpen(false)"
      >
      </ModalUpload>
    </ion-modal>
  </ion-page>
</template>

<script>
import { defineComponent,ref } from 'vue';
import { IonButtons,
  IonModal,
  IonContent,
   
  IonHeader, 
  IonMenuButton, 
  IonPage, 
  IonTitle,
   
  IonToolbar,
  IonSearchbar, 
  modalController,
  createAnimation,
  popoverController,
  alertController 
} from '@ionic/vue';
import { mapGetters } from "vuex";
import axios from 'axios'
import agregarColaborador from './agregarColaborador';
import editarColaborador from './editarColaborador';
import toast from '@/plugins/toast'
import { 
  fileTrayFullOutline,
  fileTrayFull,
  downloadOutline,
  download,
  bookmarksOutline,
  bookmarks, 
  eyeOutline, 
  eye, 
  create,
  folderOutline,
  folder,
  chatbubblesOutline,
  chatbubbles,
  clipboardOutline,
  clipboard,
  trashOutline,
  trash,
  createOutline
} from 'ionicons/icons';
import ModalUpload from '@/components/ModalUpload'
import editarSiniestro from '../siniestros/editarSiniestro';
import PopoverSiniestro from '../chat/PopoverSiniestro'
import Pusher from 'pusher-js'

export default defineComponent({
  name: 'FolderPage',
  components: {
    IonSearchbar,
    IonModal,
    ModalUpload,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
  },
  setup(){
    const isOpenRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;
    
    return {  
      isOpenRef,
      setOpen,
      fileTrayFullOutline,
      fileTrayFull,
      downloadOutline,
      download,
      bookmarksOutline,
      bookmarks,
      eyeOutline, 
      eye,
      createOutline,
      create,
      folder,
      folderOutline,
      chatbubbles,
      chatbubblesOutline,
      clipboard,
      clipboardOutline,
      trashOutline,
      trash,
    }
  },
  computed : {
    ...mapGetters([
        'getUser'
    ]),
  },
  data(){
    return {
      BasePublic: axios.defaults.baseURL,
      nombre_documento: null,
      documento: null,
      nombre_file : null,
      colaborador_selected : null,
      colaborador : null,
      siniesto_selected : null,
      siniestro : null,
      colaboradores: [],
      has_siniestro: false,
      messages: [],
      level_auth : null,
      level: null,
      message: null,
      message_edit: '',
      message_selected: null,
      loading: false,
      show_table : "notas",
      search: '',
      despacho_tipo_notas : [
        {
          type : 0,
          label : 'Privadas'
        },
        {
          type : 1,
          label : 'Despacho y colaborador'
        },
        {
          type : 2,
          label : 'Todos'
        }
      ],
      colaborador_tipo_notas : [
        {
          type : 1,
          label : 'Despacho'
        },
        {
          type : 2,
          label : 'Todos'
        },
      ], 
      options:[],
      chat_id: null,
      documentos: [],
      pusher : null
    }
  },
  mounted(){
    this.getUser.role.name != 'Administrador' ? 
      this.getColaboradoresByUser() : 
      this.getColaboradores();
    
    this.pusher = new Pusher('1c14caa5f8d38f7808c0', {
      cluster: 'ap3'
    });
  },
  methods:{
    showRow(key){
      var element = document.getElementById('trash-'+key);
      element.classList.add("delete-note");
      element.classList.remove("delete-note-not-hover");

      var element1 = document.getElementById('edit-'+key);
      element1.classList.add("delete-note");
      element1.classList.remove("delete-note-not-hover");
    },
    leaveHover(key){
      var element = document.getElementById('trash-'+key);
      element.classList.remove("delete-note");
      element.classList.add("delete-note-not-hover");

      var element1 = document.getElementById('edit-'+key);
      element1.classList.remove("delete-note");
      element1.classList.add("delete-note-not-hover");
    },
    setMessageEdit(message){
      this.message_edit = message.message 
      this.message_selected = message
    },
    async editMessage(){
      var loading = await toast.showLoading()

      await loading.present();

      axios.post("/api/chats/"+this.message_selected.id,{
        message : this.message_edit
      })
      .then(res => {
        console.log(res)
        loading.dismiss()
        this.message_selected = null
        this.message_edit = null
        this.getMessages(this.siniestro)
      })
      .catch(error => {
        loading.dismiss()
        console.log(error)
      })
    },
    getColaboradores(){
      axios.get('/api/users?role=Colaborador&all=true')
      .then(res => {
        console.log(res.data.data)
        this.colaboradores = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getSearch(){
      
      if(this.search == ''){
        this.getUser.role.name != 'Administrador' ? 
          this.getColaboradoresByUser() : 
          this.getColaboradores();
        return
      }

      var url = this.getUser.role.name != 'Administrador' ? 
        '/api/users/colaborador/byUser?all=true&filter_like='+this.search :
        '/api/users?role=Colaborador&all=true&filter_like='+this.search 
        
      axios.get(url)
      .then(res => {
        this.colaboradores = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    read_at(){
      axios.put('/api/chats/'+this.chat_id+'/read_at')
      .catch(error => {
        console.log(error)
      })
  },
  async archivar(siniestro){
    const alert = await alertController.create({
      cssClass: 'my-custom-class',
      header: '¡Confirmación!',
      message: '¿Realmente desea archivar este siniestro?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel:', blah);
          },
        },
        {
          text: 'Archivar',
          handler: async () => {
            var loading = await toast.showLoading()

            await loading.present();
            
            axios.put('/api/siniestros/'+siniestro.id+'/archivar')
            .then(() => {
              loading.dismiss()
              toast.openToast("Siniestro archivado correctamente","success",2000)
              this.getSiniestrosByUser(this.colaborador);
            })
            .catch(error => {
              loading.dismiss()
              console.log(error)
            })
            },
          },
        ],
      });
      return alert.present();
    },
    getSiniestrosByUser(colaborador){
      
      this.colaborador_selected = colaborador.id
      this.colaborador = colaborador

      axios.get('/api/siniestros/by/user/'+colaborador.id)
      .then(res => {
        
        this.has_siniestro = false
        this.siniestros = res.data.data
        this.siniestros.data.length == 0 ? this.has_siniestro = false : this.has_siniestro = true;

        this.level_auth = this.getUser.role.level

        if (this.getUser.role.name == 'Colaborador'){
          this.options = this.colaborador_tipo_notas
        }else{
          this.options = this.despacho_tipo_notas
        }

        if (this.getUser.role.name == 'Cliente') {
          this.input_styles['bottom'] = '30px'
          this.messages_styles['height'] = '80%'
        }
       
        this.level = this.options[0].type
        
      })
      .catch(error => {
        console.log(error)
      })
    },
    getMessages(siniestro){
      
      this.show_table = "notas"
      this.siniestro = siniestro
      this.siniesto_selected = siniestro.id
      
      if (this.getUser.role.name == 'Colaborador'){
        this.options = this.colaborador_tipo_notas
      }else{
        this.options = this.despacho_tipo_notas
      }

      if (this.getUser.role.name == 'Cliente') {
        this.input_styles['bottom'] = '30px'
        this.messages_styles['height'] = '80%'
      }
       
      this.level = this.options[0].type

      if (siniestro.chat ==  null ) {
        this.messages = []
        return;
      }

      axios.get('/api/chats/'+siniestro.chat.id)
      .then(res => {
        this.messages = res.data.data;
        this.chat_id = siniestro.chat.id

        this.pusher.unsubscribe('messages-'+this.chat_id);
        
        var self = this
        
        var channel = this.pusher.subscribe('chat');

        channel.bind('messages-'+this.chat_id, function(data) {
          if (data.user_id != self.getUser.id && data.level != 0) {
            const music = new Audio('/assets/sounds/Plink.mp3');
            music.play();
            self.getMessages(self.siniestro)
          }
        });


        this.read_at()
      })
      .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    async openPopover(ev) {
      
      const popover = await popoverController.create({
        component: PopoverSiniestro,
        cssClass: 'class',
        event: ev,
        translucent: false,
        showBackdrop : false,
        componentProps : {siniestro : this.siniestro}
      });
      
      await popover.present();

      const { role } = await popover.onDidDismiss();
      
      console.log('onDidDismiss resolved with role', role);
    },
    getDocumentos(siniestro){
      
      this.show_table = "documentos"
      this.siniesto_selected = siniestro.id
      this.siniestro = siniestro

      axios.get('/api/documentos/'+siniestro.id)
      .then(res => {
        this.documentos = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    async send(){

      var loading = await toast.showLoading()

      await loading.present();

      let data = {
        message : this.message,
        chat_id : this.chat_id,
        level : this.getUser.role.name == 'Cliente' ? 2 : this.level,
        is_file: false
       }

      axios.post('/api/chats',data)
      .then(res => {
        console.log(res)
        loading.dismiss()
        this.message = null;
        this.getMessages(this.siniestro)
      })
      .catch(error => {
        loading.dismiss()
        console.log(error);
      });

    },
    async getPhoto($event){
      
      this.setOpen(false)
      
      var loading = await toast.showLoading()

      await loading.present();

      var formData = new FormData;

      formData.append('chat_id',this.chat_id);
      formData.append('is_file',true);
      formData.append('level', this.getUser.role.name == 'Cliente' ? 2 : this.level);
      
      for (var i = 0; i < $event.files.length; i++) {
         formData.append('files-'+i,$event.files[i]);
      }

      axios.post('/api/chats',formData)
       .then(res => {
          loading.dismiss()
          console.log(res)
          this.message = null;
          this.getMessages(this.siniestro)
       })
       .catch(error => {
          loading.dismiss()
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    getColaboradoresByUser(){
      axios.get('/api/users/colaborador/byUser?all=true')
      .then(res => {
        console.log(res.data.data)
        this.colaboradores = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    page(url){
      axios.get(url)
       .then(res => {
         this.colaboradores = res.data.data;
       })
       .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    pageSiniestro(url){
      this.loading = true
      axios.get(url)
       .then(res => {

        this.loading = false
        this.siniestros = res.data.data
        console.log(this.siniestros)
       })
       .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    download_documento(documento){
      if (documento.documento_firmados.length == 0 ) {
        toast.openToast("Los cliente aun no ha firmado los documentos","error",2000)
        return
      }
      for (var i = 0; i < documento.documento_firmados.length; i++) {
        window.open(axios.defaults.baseURL+'/'+documento.documento_firmados[i].pivot.path, "_blank"); 
      }
    },
    showClienteByColaborador(colaborador){
      this.$router.push({name : 'cliente_coloborador',params : {id:colaborador.id}})
    },
    async openModalAdd() {
    
      const modal = await modalController
        .create({
          component: agregarColaborador,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
        })
     
      modal.present();

      modal.onDidDismiss().then((data) => {
        this.getUser.role.name != 'Administrador' ? this.getColaboradoresByUser() : this.getColaboradores();
        console.log(data)
      })

    },
    showDocumentos(siniestro_id){
      this.$router.push({ name: 'documentos' , params : {siniestro_id : siniestro_id}})
    },

    async openModalSiniestroEdit(siniestro) {
    
      const modal = await modalController
        .create({
          component: editarSiniestro,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
          componentProps : {data_siniestro : siniestro}
        })
    
      modal.present();
      
      modal.onDidDismiss().then((data) => {
        console.log(data)
        this.getSiniestrosByUser({id : this.colaborador_selected})
      })
    },
    async openModalEdit(colaborador) {
    
      const modal = await modalController
      .create({
        component: editarColaborador,
        cssClass: 'class-modal',
        keyboardClose : true,
        enterAnimation: this.enterAnimation,
        leaveAnimation: this.leaveAnimation,
        componentProps : {colaborador : colaborador}
      })

      modal.present();

      modal.onDidDismiss().then((data) => {
        console.log(data)
        this.getUser.role.name != 'Administrador' ? this.getColaboradoresByUser() : this.getColaboradores();
      })

    },
    file(){
      document.querySelector('#documento').click()
    },
    getFile(){
      this.documento = this.$refs.documentos.files[0]
      this.nombre_file = 'Nombre del Archivo: '+  this.documento.name
    },
    async postDocumento(){
      
      if (this.nombre_documento == null) {
        this.$toast.openToast("El nombre del documento es requerido","success")
        return;
      }

      var loading = await toast.showLoading()

      await loading.present();

      var formData = new FormData();
      
      formData.append('siniestro_id',this.siniesto_selected)
      formData.append('nombre_documento',this.nombre_documento)
      formData.append('documento',this.documento)

      axios.post('/api/documentos',formData)
      .then(data =>{
        loading.dismiss()
        this.$toast.openToast("Registro de documento exitoso","success")
        this.getDocumentos({id :this.siniesto_selected})
        this.nombre_file = null
        this.nombre_documento = null
        
        console.log(data)
      }).catch(err =>{
        loading.dismiss()
        console.log(err)
      })
    },
     async eliminarUserConfirmacion(user) {

        const alert = await alertController.create({
          cssClass: 'my-custom-class',
          header: '¡Confirmación!',
          message: '¿Realmente desea eliminar este Colaborador?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel:', blah);
              },
            },
            {
              text: 'Eliminar',
              handler: async () => {
                var loading = await toast.showLoading()

                await loading.present();
                
                console.log('Confirm Okay');
                
                axios.delete('/api/users/'+user.id)
                .then(data =>{
                  loading.dismiss()
                  this.getUser.role.name != 'Administrador' ? this.getColaboradoresByUser() : this.getColaboradores();
                  toast.openToast("Colaborador eliminado exitosamente","error",2000)
                  console.log(data)

                }).catch(err =>{
                  loading.dismiss()
                  console.log(err)
                })
              },
            },
          ],
        });
        return alert.present();
      },
    async eliminarSiniestroConfirmacion(siniestro) {

        const alert = await alertController.create({
          cssClass: 'my-custom-class',
          header: '¡Confirmación!',
          message: '¿Realmente desea eliminar este siniestro?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel:', blah);
              },
            },
            {
              text: 'Eliminar',
              handler: async () => {
                var loading = await toast.showLoading()

                await loading.present();
                
                console.log('Confirm Okay');
                
                axios.delete('/api/siniestros/'+siniestro.id)
                .then(data =>{
                  loading.dismiss()
                  toast.openToast("Siniestro eliminado exitosamente","error",2000)
                  this.getSiniestrosByUser({id : this.colaborador_selected})
                  console.log(data)

                }).catch(err =>{
                  loading.dismiss()
                  console.log(err)
                })
              },
            },
          ],
        });
        return alert.present();
      },
      async eliminarNotaConfirmacion(nota) {

        const alert = await alertController.create({
          cssClass: 'my-custom-class',
          header: '¡Confirmación!',
          message: '¿Realmente desea eliminar esta nota?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel:', blah);
              },
            },
            {
              text: 'Eliminar',
              handler: async () => {
                var loading = await toast.showLoading()

                await loading.present();
                
                console.log('Confirm Okay');
                
                axios.delete('/api/chats/'+nota.id+'/message')
                .then(data => {
                  loading.dismiss()
                  this.getMessages(this.siniestro)
                  console.log(data)
                }).catch(err =>{
                  loading.dismiss()
                  console.log(err)
                })
              },
            },
          ],
        });
        return alert.present();
      },
     enterAnimation : function () {
      let baseEl = document
        const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' }
        ]);

      return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation  : function () {
       return this.enterAnimation(document).direction('reverse');
    },
  }
});
</script>

<style type="text/css">

  .class .popover-content{
    width: 300px;
  }

</style>

<style scoped>

.delete-note{
  display:block;
}

.delete-note-not-hover{
  display:none;
}

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
  font-size: 13px;
}

.input-text{
  margin-bottom: 15px;
  padding-left: 17px;
  background: #F5F5F5;
  border: 1px solid rgba(183, 183, 183, 0.3);
  box-sizing: border-box;
  border-radius: 24px;
  width :90%;
  height : 48px;
}


.input-text-edit{
  margin-bottom: 15px;
  padding-left: 17px;
  background: #c1c1c1;
  border: 1px solid rgba(183, 183, 183, 0.3);
  box-sizing: border-box;
  border-radius: 24px;
  width: 90%;
  height: 120px;
}
.selected{
  background-color: #bac1eb !important;
}
</style>