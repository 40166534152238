<template>
 
  <ion-content >
  <div id="container"  style="margin-left: 24px;margin-right: 24px;">
    <ion-row>
    <ion-col>
    </ion-col>
    <ion-col>
    </ion-col>
    <ion-col>
       <button type="button" @click="closeModal()" style="margin-left: 67%;margin-top: 6px;background-color: #fff;">
        <ion-icon :icon="close"></ion-icon> 
      </button>
    </ion-col>
  </ion-row>
    <ion-title> Editar Oficina </ion-title>
    <ion-form>
      <ion-input type="text" id="nombre" v-model="nombre" placeholder="Ingrese el nombre" />
      <ion-input type="text" id="user" v-model="user"   placeholder="Ingrese el usuario" />
      <ion-input type="email" id="email" v-model="email"  placeholder="Ingrese el correo" />
      <ion-input type="text" id="telefono" v-model="telefono"   placeholder="Ingrese el teléfono" />
      <ion-select class="input-text"  placeholder="Ingrese la provincia"  interface="action-sheet" v-model="provincia" > 
        <ion-select-option v-for="provincia in provincias" :value="provincia.name" :key="provincia" >{{provincia.name}}</ion-select-option>
      </ion-select>
      <ion-input type="text" id="poblacion" v-model="poblacion"   placeholder="Ingrese el poblacion" />
      <ion-input type="text" id="grupo" v-model="grupo"   placeholder="Ingrese el grupo" />
      <ion-textarea id="observacion" v-model="observacion" placeholder="Ingrese el observación" />
      
      <ion-input type="password" id="password" v-model="password"   placeholder="Ingrese la contraseña" />
      <ion-input type="password" id="password_confirmation" v-model="password_confirmation"   placeholder="Repite la contraseña" />
      <ion-button color="dark" @click="editColaborador">
        Enviar
      </ion-button>
    </ion-form>
  </div>
</ion-content>

</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { modalController,IonInput,IonSelect,IonTextarea } from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'
import provincias from '../clinicas/provincia_spain.json';

export default defineComponent({
  name: 'AgregarColaborador',
  props : ['colaborador'],
  components :{IonInput,IonSelect,IonTextarea},
  setup() {
    return { close }
  },
  data(){
    return{
      provincias,
      id : null,
      user: null,
      email: null,
      password: null,
      nombre : null,
      telefono : null,
      provincia : null,
      poblacion : null,
      grupo : null,
      observacion : null,
    }
  },
  mounted(){
    this.user = this.colaborador.user_name
    this.id = this.colaborador.id
    this.email = this.colaborador.email
    this.nombre = this.colaborador.name
    this.telefono = this.colaborador.telefono
    this.provincia = this.colaborador.provincia
    this.poblacion = this.colaborador.poblacion
    this.grupo = this.colaborador.grupo
    this.observacion = this.colaborador.observacion
  },
  methods:{
    async closeModal() {
      const modal = await modalController
      return modal.dismiss();
    },
    async editColaborador(){
      var loading = await toast.showLoading()

      await loading.present();

      axios.post('/api/users/'+this.id,{
        user_name : this.user,
        email : this.email,
        name : this.nombre,
        telefono : this.telefono,
        provincia : this.provincia,
        grupo : this.grupo,
        poblacion : this.poblacion,
        observacion : this.observacion,
        password : this.password,
        password_confirmation : this.password_confirmation
      })
      .then(data =>{
        console.log(data)
         loading.dismiss()
         this.closeModal()
        this.$toast.openToast("Modificacion de colaborador exitosa","success")
      }).catch(err =>{
         loading.dismiss()
        console.log(err)
      })
    }
  }
});
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
ion-select {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
  height: 50px;
}
ion-textarea {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}
</style>
