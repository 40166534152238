<template>
 
  <ion-content >
  <div id="container"  style="margin-left: 24px;margin-right: 24px;">
    <ion-row>
    <ion-col>
    </ion-col>
    <ion-col>
    </ion-col>
    <ion-col>
       <button type="button" @click="closeModal()" style="margin-left: 67%;margin-top: 6px;background-color: #fff;">
        <ion-icon :icon="close"></ion-icon> 
      </button>
    </ion-col>
  </ion-row>
    <ion-title> Agregar Oficina </ion-title>
    <ion-form>
      <ion-input type="text" id="user"  v-model="user"  placeholder="Ingrese el Usuario" />
      <ion-input type="email" id="email" v-model="email"  placeholder="Ingrese el Correo" />
      <ion-input type="password" id="password" v-model="password"   placeholder="Ingrese la contraseña" />
      <ion-input type="password" id="password_confirmation" v-model="password_confirmation"   placeholder="Repite la contraseña" />
      <ion-button color="dark" @click="postColaborador">
        Enviar
      </ion-button>
    </ion-form>
  </div>
</ion-content>

</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { modalController,IonInput } from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'

export default defineComponent({
  name: 'AgregarColaborador',
  components :{IonInput},
  setup() {
    return { close }
  },
  data(){
    return{
      colaboradores : [],
      user: null,
      email: null,
      password: null,
      password_confirmation : null,
      role_id: null
    }
  },
  mounted(){
    this.getRoleId()
  },
  methods:{
    async closeModal() {
      const modal = await modalController
      return modal.dismiss();
    },
    event($event){
      this[$event.target.id] = $event.target.value
    },
    async getRoleId(){
   
      axios.get('/api/roles/Colaborador/name')
      .then(data =>{
        this.role_id = data.data.data.id
      }).catch(err =>{
        console.log(err)
      })
    },
    async postColaborador(){
      var loading = await toast.showLoading()

      await loading.present();

      axios.post('/api/users',{
          user_name : this.user,
          email : this.email,
          password : this.password,
          password_confirmation : this.password_confirmation,
          role_id : this.role_id
      })
      .then(data =>{
        loading.dismiss()
        this.closeModal()
        this.$toast.openToast("Registro de colaborador exitoso","success")
        console.log(data)
      }).catch(err =>{
        loading.dismiss()
        console.log(err)
      })
    }
  }
});
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
</style>
